// import React from "react"

// import { Heading2 } from "../../StyledComponents/typography.css"
// import PrivateDiningForm from "./PrivateDiningForm/PrivateDiningForm"
// import { FormWrapper, TitleWrapper } from "./PrivateDining.css"

// const DiningForm = ({ makeAndEnquiryHeading }) => {
//   return (
//     <FormWrapper>
//       <TitleWrapper>
//         <Heading2 color="black">{makeAndEnquiryHeading}</Heading2>
//       </TitleWrapper>
//       <PrivateDiningForm />
//     </FormWrapper>
//   )
// }

// export default DiningForm
import React from "react"


import { Heading2 } from "../../StyledComponents/typography.css"
import PrivateDiningForm from "./PrivateDiningForm/PrivateDiningForm"
import { FormWrapper, TitleWrapper } from "./PrivateDining.css"
import FunctionTrackerForm from "./PrivateDiningForm/FunctionTrackerForm"

const DiningForm = ({ makeAndEnquiryHeading }) => {
React.useEffect(() => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) {
    return
  }

  return console.log(
    "form info:", window &&    window.ftenquireinit(907, "smithstreetbistrot")
  )
},[])


  return (
    <FormWrapper>
      <TitleWrapper>
        <Heading2 color="black">{makeAndEnquiryHeading}</Heading2>
      </TitleWrapper>
      <FunctionTrackerForm/>
    </FormWrapper>
  )
}
export default DiningForm
